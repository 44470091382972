<section id="content">
	<!--	<div *ngIf="loading" class="loader" id="loader" [ngClass]="{'loading':!loading}">-->
	<!--		<div class="preloader"></div>-->
	<!--	</div>-->
	<ngx-spinner name="iframe" bdColor="rgba(0, 0, 0, 0.85)"></ngx-spinner>

	<iframe perfectScrollbar name="main" id="iframe" [src]="url | safe" (load)="loaded()"
					[class.loading]="loading"
					[class.width-sm]="class=='width-sm'"
					[class.width-md]="class=='width-md'"
					[class.width-lg]="class=='width-lg'"

					sandbox="allow-downloads allow-modals allow-scripts allow-forms allow-popups allow-top-navigation allow-same-origin allow-popups-to-escape-sandbox"

	>

	</iframe>
</section>



