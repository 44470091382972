import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule} from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule, MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
// ALL TIME REQUIRED
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import {HeaderSideComponent} from "./components/header-side/header-side.component";
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import {HeaderTopComponent} from "./components/header-top/header-top.component";
import {AdminLayoutComponent} from "./components/layouts/admin-layout/admin-layout.component";
import {AuthLayoutComponent} from "./components/layouts/auth-layout/auth-layout.component";
import {NotificationsComponent} from "./components/notifications/notifications.component";
import {SidebarSideComponent} from "./components/sidebar-side/sidebar-side.component";
import {SidebarTopComponent} from "./components/sidebar-top/sidebar-top.component";
import {SidenavComponent} from "./components/sidenav/sidenav.component";
import {TarifariosComponent} from "./components/tarifarios/tarifarios.component";
// SERVICES
import {DecimalNumberDirective} from "./directives/decimal-number-directive";
import {DisableControlDirective} from "./directives/disable-control-directive";
// DIRECTIVES
import {DropdownAnchorDirective} from "./directives/dropdown-anchor.directive";
import {DropdownLinkDirective} from "./directives/dropdown-link.directive";
import {AppDropdownDirective} from "./directives/dropdown.directive";
import {EgretSideNavToggleDirective} from "./directives/egret-side-nav-toggle.directive";
import {FontSizeDirective} from "./directives/font-size.directive";
import {OnlyNumberDirective} from "./directives/only-number.directive";
import {ScrollToDirective} from "./directives/scroll-to.directive";
// PIPES
import {ExcerptPipe} from "./pipes/excerpt.pipe";
import {GetValueByKeyPipe} from "./pipes/get-value-by-key.pipe";
import {RelativeTimePipe} from "./pipes/relative-time.pipe";
import {AppComfirmComponent} from "./services/app-confirm/app-confirm.component";

import {AppConfirmService} from "./services/app-confirm/app-confirm.service";
import {AppLoaderComponent} from "./services/app-loader/app-loader.component";
import {AppLoaderService} from "./services/app-loader/app-loader.service";
import {LayoutService} from "./services/layout.service";
import {MainProductServices} from "./services/main-product.service";
import {NavigationService} from "./services/navigation.service";
import {RoutePartsService} from "./services/route-parts.service";
import {ThemeService} from "./services/theme.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";

const classesToInclude = [
	HeaderTopComponent,
	SidebarTopComponent,
	SidenavComponent,
	NotificationsComponent,
	TarifariosComponent,
	SidebarSideComponent,
	HeaderSideComponent,
	AdminLayoutComponent,
	AuthLayoutComponent,
	BreadcrumbComponent,
	AppComfirmComponent,
	AppLoaderComponent,
	FontSizeDirective,
	ScrollToDirective,
	AppDropdownDirective,
	DropdownAnchorDirective,
	DropdownLinkDirective,
	EgretSideNavToggleDirective,
	OnlyNumberDirective,
	DecimalNumberDirective,
	RelativeTimePipe,
	ExcerptPipe,
	GetValueByKeyPipe,
	DisableControlDirective
];
const materialToInclude = [FlexLayoutModule,
	TranslateModule,
	MatSidenavModule,
	MatListModule,
	MatTooltipModule,
	MatOptionModule,
	MatSelectModule,
	MatMenuModule,
	MatSnackBarModule,
	MatGridListModule,
	MatToolbarModule,
	MatIconModule,
	MatButtonModule,
	MatRadioModule,
	MatCheckboxModule,
	MatCardModule,
	MatProgressSpinnerModule,
	MatRippleModule,
	MatDialogModule,
	PerfectScrollbarModule,
	MatTableModule,
	MatPaginatorModule,
	MatSortModule,
	MatFormFieldModule,
	MatInputModule]
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		...materialToInclude

	],
	entryComponents: [AppComfirmComponent, AppLoaderComponent],
	providers: [
		ThemeService,
		LayoutService,
		MainProductServices,
		NavigationService,
		RoutePartsService,
		AppConfirmService,
		AppLoaderService
	],
	declarations: [classesToInclude],
	exports: [classesToInclude,
		...materialToInclude]
})
export class SharedModule {
}
