import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable()
export class MainProductServices {
	products = new BehaviorSubject<any>(environment.products);
	products$ = this.products.asObservable();

	getMainProduct(): string {
		return window.localStorage['typeToken'] ? window.localStorage['typeToken'] : 'SOAT';
	}

	saveMainProduct(token: string) {
		window.localStorage['typeToken'] = token;
	}
}
