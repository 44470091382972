import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IframeComponent} from "./iframe.component";
import {PipesModule} from "../pipes/pipes.module";
import {NgxSpinnerModule} from "ngx-spinner";

@NgModule({
	declarations: [IframeComponent],
	imports: [
		CommonModule, PipesModule, NgxSpinnerModule
	],
	exports: [IframeComponent]
})
export class IframeModule {
}
