<div class="header-topnav mat-elevation-z2">
	<div class="container">
		<div class="topnav">
			<!-- App Logo -->
			<div class="topbar-branding"><img src="assets/images/logo_protecta.png" alt="" class="app-logo" /></div>

			<ul class="menu" *ngIf="!layoutConf.isMobile">
				<li *ngFor="let item of menuItems; let i = index">
					<div *ngIf="item.type !== 'separator'" routerLinkActive="open">
						<a matRipple routerLink="/{{ item.state }}" *ngIf="item.type === 'link'">
							<mat-icon>{{ item.icon }}</mat-icon>
							{{ item.name | translate }}
						</a>
						<div *ngIf="item.type === 'dropDown'">
							<label matRipple for="drop-{{ i }}" class="toggle"
								><mat-icon>{{ item.icon }}</mat-icon> {{ item.name | translate }}</label
							>
							<a matRipple
								><mat-icon>{{ item.icon }}</mat-icon> {{ item.name | translate }}</a
							>
							<input type="checkbox" id="drop-{{ i }}" />
							<ul>
								<li *ngFor="let itemLvL2 of item.sub; let j = index" routerLinkActive="open">
									<a matRipple routerLink="{{ item.state ? '/' + item.state : '' }}/{{ itemLvL2.state }}" *ngIf="itemLvL2.type !== 'dropDown'">
										<mat-icon *ngIf="itemLvL2.icon">{{ itemLvL2.icon }}</mat-icon>
										{{ itemLvL2.name | translate }}
									</a>

									<div *ngIf="itemLvL2.type === 'dropDown'">
										<label matRipple for="drop-{{ i }}{{ j }}" class="toggle">{{ itemLvL2.name | translate }}</label>
										<a matRipple
											><mat-icon *ngIf="itemLvL2.icon">{{ itemLvL2.icon }}</mat-icon> {{ itemLvL2.name | translate }}</a
										>
										<input type="checkbox" id="drop-{{ i }}{{ j }}" />
										<!-- Level 3 -->
										<ul>
											<li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
												<a
													matRipple
													routerLink="{{ item.state ? '/' + item.state : '' }}{{ itemLvL2.state ? '/' + itemLvL2.state : '' }}/{{ itemLvL3.state }}"
												>
													<mat-icon *ngIf="itemLvL3.icon">{{ itemLvL3.icon }}</mat-icon>
													{{ itemLvL3.name | translate }}
												</a>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</li>
			</ul>
			<span fxFlex></span>

			<button mat-icon-button matTooltip="Tarifarios" (click)="toggleNotificSw()" [style.overflow]="'visible'" class="topbar-button-right tarifario">
				<mat-icon class="tarifario-icon">apps</mat-icon>
				<mat-label>{{ titleMenu }}</mat-label>
			</button>


			<!-- Top left user menu -->
			<button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right mr-1 img-button">
				<!-- 	<img src="assets/images/face-7.jpg" alt="" /> -->
				<mat-icon>menu</mat-icon>
			</button>
			<mat-menu #accountMenu="matMenu">
				<button *ngIf="layoutConf.isMobile" mat-menu-item [routerLink]="['/dashboard']">
					<mat-icon>account_box</mat-icon>
					<span>Inicio</span>
				</button>

				<button mat-menu-item (click)="logout()">
					<mat-icon>exit_to_app</mat-icon>
					<span>Cerrar sesión</span>
				</button>
			</mat-menu>

		</div>
	</div>
</div>
