import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {MainProductServices} from "../../services/main-product.service";
import {untilDestroyed} from "ngx-take-until-destroy";
import {tap} from "rxjs/operators";

@Component({
    selector: 'app-tarifarios',
    templateUrl: './tarifarios.component.html'
})

export class TarifariosComponent implements OnInit, OnDestroy {

	@Input() tarifarioPanel;
	@Output() messageTitle: EventEmitter<string> = new EventEmitter<string>();
	data: any[];
	visible: boolean = false;

    tarifario = [];

    constructor(private router: Router, private typeService: MainProductServices) {

    }

    ngOnInit(): void {
        this.typeService.products$
            .pipe(
                untilDestroyed(this),
                tap(pro => this.tarifario = pro)
            )
            .subscribe()

        this.router.events.subscribe((routeChange) => {
            if (routeChange instanceof NavigationEnd) {
                this.tarifarioPanel.close();
            }
        });
    }

    ngOnDestroy(): void {
    }

    togglrTarifario(change: any) {
        this.typeService.saveMainProduct(change);
        // if (window.location.pathname === "/dashboard") {
        //     window.location.reload();
        // } else {
        //     this.router.navigate(["/dashboard"]);
        // }
    }
}
