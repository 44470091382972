import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private cookiesService: CookieService,
							private spinner: NgxSpinnerService,
							private router: Router) {
	}


	signOut() {

		Auth.signOut().then(value => {
			this.cookiesService.deleteAll('/', '.devmente.com');
			window.location.href = environment.login + '?redirectTo=' + window.location.href.split('#')[0];

		}).catch(err => {
			this.spinner.hide();
		});
	}

	public isAuthenticatedGuard(): Observable<boolean> {
		const tokenValue = this.cookiesService.get('access_token');

		return of(!!tokenValue);
	}
}
