import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { IframeComponent } from "./shared/iframe/iframe.component";
import { AuthGuard } from "./auth/auth.guard";

export const rootRouterConfig: Routes = [
	{
		path: "",
		redirectTo: "dashboard",
		pathMatch: "full",
	},
	{
		path: "",
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: "dashboard",
				loadChildren: () =>
					import("./views/dashboard/dashboard.module").then(
						(m) => m.DashboardModule
					),
				data: { title: "Inicio", breadcrumb: "INICIO" },
			},
			{
				path: "cotizador-sctr",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Cotizador", breadcrumb: "Cotizador SCTR" },
			},
			{
				path: "tarifario-sctr",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Tarifario", breadcrumb: "Tarifario SCTR" },
			},
			{
				path: "cotizador",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Cotizador", breadcrumb: "Cotizador" },
			},
			{
				path: "tarifario",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Tarifario", breadcrumb: "Tarifario" },
			},
			{
				path: "bonos-masivos",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Bonos Masivos", breadcrumb: "Bonos Masivos" },
			},
			{
				path: "tarifario-acc-personales",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Tarifario", breadcrumb: "Tarifario AP/VG" },
			},
			{
				path: "cotizador-acc-personales",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Cotizador", breadcrumb: "Cotizador AP/VG" },
			},
			{
				path: "tarifario-vida-grupo",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Tarifario", breadcrumb: "Tarifario VG" },
			},
			{
				path: "cotizador-vida-grupo",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Cotizador", breadcrumb: "Cotizador VG" },
			},
			{
				path: "tarifario-vida-credito",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Tarifario", breadcrumb: "Tarifario Vida Crédito" },
			},
			{
				path: "tarifario-vigp",
				canActivate: [AuthGuard],
				component: IframeComponent,
				data: { title: "Tarifario", breadcrumb: "Tarifario Vida Inversión" },
			}
		],
	},
	{
		path: "**",
		redirectTo: "sessions/404",
	},
];
