<div class="app-admin-wrap" [dir]='layoutConf.dir'>
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
  <app-header-top
    *ngIf="layoutConf.navigationPos === 'top'"
		[tarifarioPanel]="tarifPanel"
		[titleMenu]="titleMenu"
	>
  </app-header-top>
  <!-- Main Container -->
  <mat-sidenav-container
		[dir]='layoutConf.dir'
		class="app-admin-container app-side-nav-container mat-drawer-transition"
		[ngClass]="{
			'navigation-top': layoutConf.navigationPos === 'top',
			'sidebar-full': layoutConf.sidebarStyle === 'full',
			'sidebar-compact': layoutConf.sidebarStyle === 'compact' && layoutConf.navigationPos === 'side',
			'sidebar-compact-big': layoutConf.sidebarStyle === 'compact-big' && layoutConf.navigationPos === 'side',
			'layout-intransition': layoutConf.layoutInTransition,
			'sidebar-opened': layoutConf.sidebarStyle !== 'closed' && layoutConf.navigationPos === 'side',
			'sidebar-closed': layoutConf.sidebarStyle === 'closed',
			'fixed-topbar': layoutConf.topbarFixed && layoutConf.navigationPos === 'side'
		}">
		<!-- SIDEBAR -->
		<!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
		<app-sidebar-side *ngIf="layoutConf.navigationPos === 'side'"></app-sidebar-side>

		<!-- Top navigation layout (navigation for mobile screen) -->
		<!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
		<app-sidebar-top *ngIf="layoutConf.navigationPos === 'top' && layoutConf.isMobile"></app-sidebar-top>

			<!-- App content -->
			<div class="main-content-wrap" [perfectScrollbar]="scrollConfig" [disabled]="layoutConf.topbarFixed">
				<!-- Header for side navigation layout -->
				<!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
				<app-header-side
					*ngIf="layoutConf.navigationPos === 'side'"
					[tarifarioPanel]="tarifPanel">
				</app-header-side>

				<div class="rightside-content-hold" [perfectScrollbar]="scrollConfig" [disabled]="!layoutConf.topbarFixed">
					<!-- View Loader -->
					<div class="view-loader" *ngIf="isModuleLoading">
						<div class="spinner">
							<div class="double-bounce1 mat-bg-accent"></div>
							<div class="double-bounce2 mat-bg-primary"></div>
						</div>
					</div>
					<!-- Breadcrumb -->
					<app-breadcrumb></app-breadcrumb>
					<!-- View outlet -->
					<router-outlet></router-outlet>
				</div>
			</div>
			<!-- View overlay for mobile navigation -->
			<div class="sidebar-backdrop"
			[ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
			(click)="closeSidebar()"></div>


			<mat-sidenav #tarifPanel mode="over" class="" position="end">
				<div class="navigation-hold" fxLayout="column">
					<app-tarifarios [tarifarioPanel]="tarifPanel"
					(messageTitle)="titleMenu = $event"
					></app-tarifarios>
				</div>
			</mat-sidenav>
  </mat-sidenav-container>

</div>

