// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	newClient: "7vclcsal8kvtb5hf8uhe0tb94r",
	newDomain: ".ofvi.dev.protectasecuritycloud.pe",
	keyCloakUrl: "https://protecta.devmente.io:8443/auth",
	soatBaseApiUrl: "protecta.devmente.io:8443/soat",
	sctrBaseApiUrl: "protecta.devmente.io:8443/kuntur",
	integrationApiUrl: "protecta.devmente.io:8443",
	products: [
		{
			message: "Cotizador SCTR",
			type: "CSCTR",
			icon: "person_pin",
			time: "",
			route: "/cotizador-sctr",
			urlIframe: "https://cotizador.sctr.dev.protectasecuritycloud.pe",
			color: "primary"
		},
		{
			message: "Tarifario SCTR",
			type: "TSCTR",
			icon: "person_pin",
			time: "",
			route: "/tarifario-sctr",
			urlIframe: "https://tarifariosctr.ecom.dev.protectasecuritycloud.pe",
			color: "primary"
		},
		{
			message: "Cotizador Vida Ley",
			type: "CLWLT",
			icon: "person_pin",
			time: "",
			route: "/cotizador",
			urlIframe: "https://cotizador.lwlt.dev.protectasecuritycloud.pe",
			color: "primary"
		},
		{
			message: "Tarifario Vida Ley",
			type: "TLWLT",
			icon: "directions_car",
			time: "",
			urlIframe: "https://matriz.lwlt.dev.protectasecuritycloud.pe",
			route: "/tarifario",
			color: "primary"
		},
		{
			message: "Bonos masivos",
			type: "BM",
			icon: "person_pin",
			time: "",
			urlIframe: "https://mbst.dev.protectasecuritycloud.pe",
			route: "/bonos-masivos",
			color: "primary"
		},
		{
			message: "Tarifario Acc. Personales",
			type: "TACPR",
			icon: "person_pin",
			time: "",
			urlIframe: "https://matrix.acpr.dev.protectasecuritycloud.pe",
			route: "/tarifario-acc-personales",
			color: "primary"
		},
		{
			message: "Cotizador Acc. Personales",
			type: "CACPR",
			icon: "person_pin",
			time: "",
			urlIframe: "https://quoter.acpr.dev.protectasecuritycloud.pe",
			route: "/cotizador-acc-personales",
			color: "primary"
		},
		{
			message: "Tarifario Vida Grupo",
			type: "TTCVG",
			icon: "person_pin",
			time: "",
			urlIframe: "https://matrix.tcvg.dev.protectasecuritycloud.pe",
			route: "/tarifario-vida-grupo",
			color: "primary"
		},
		{
			message: "Cotizador Vida Grupo",
			type: "CTCVG",
			icon: "person_pin",
			time: "",
			urlIframe: "https://quoter.tcvg.dev.protectasecuritycloud.pe",
			route: "/cotizador-vida-grupo",
			color: "primary"
		},
		{
			message: "Tarifario Desgravamen - Vida Individual",
			type: "TTCDS",
			icon: "person_pin",
			time: "",
			urlIframe: "https://matrix.tcds.dev.protectasecuritycloud.pe",
			route: "/tarifario-desgravamen-vida-individual",
			color: "primary"
		},
		{
			message: "Tarifario VIGP",
			type: "TVIGP",
			icon: "person_pin",
			time: "",
			route: "/tarifario-vigp",
			urlIframe: "https://vdiv.dev.protectasecuritycloud.pe",
			color: "primary"
		}
	],
	aws: {
		aws_project_region: "us-east-1",
		aws_cognito_identity_pool_id: "us-east-1:594902652773",
		aws_cognito_region: "us-east-1",
		aws_user_pools_id: "us-east-1_UU9Z6a0n3",
		aws_user_pools_web_client_id: "tcq53h177im86qdnodmalvfcl",
		cookieStorage: {
			domain: ".dev.protectasecuritycloud.pe",
			path: "/",
			secure: false
		},
		oauth: {}
	},
	login: "https://login.core.dev.protectasecuritycloud.pe/#/auth/sign-in"
};

/* export const environment = {
	production: false,
	keyCloakUrl: 'https://tarifario.protectasecurity.pe/auth/',
	soatBaseApiUrl: 'tarifario.protectasecurity.pe/soat',
	sctrBaseApiUrl: 'tarifario.protectasecurity.pe/kuntur',
	integrationApiUrl: 'tarifario.protectasecurity.pe'
}; */
