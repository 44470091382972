/*
 *
 * Yoan Asdrubal Quintana Ramirez.
 *  18/3/2019
 *
 */

import {AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {filter, tap} from 'rxjs/operators';
import {fromEvent} from 'rxjs';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {MainProductServices} from "../services/main-product.service";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";


@Component({
	selector: 'app-iframe',
	templateUrl: './iframe.component.html',
	styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit, AfterViewInit, OnDestroy {
	title = '';
	@Input('url') url = 'about:blank';
	@Input('loading') loading = true;
	public settings = {};
	@Input('external') external = false;

	class: string; // width-sm (<=1024) , width-md (1024 > size <= 1366) , width-lg ( 1366 >)
	constructor(private typeService: MainProductServices, public router: Router,
							private _spinner: NgxSpinnerService
	) {

	}

	ngOnInit() {
		this._spinner.show('iframe');
		this.typeService
			.products$
			.pipe(
				untilDestroyed(this),
				tap((urls: any[]) => {
					const url = this.router.url;
					// console.log('url', url, urls);

					const urlOption = urls.filter((u: any) => u.route === url)[0];
					if (!!urlOption)
						this.url = urlOption.urlIframe;
				})
			)
			.subscribe()
		// Determinando la url que debe mostrarse en el iframe.
		if (!this.external) {
		}

		// Procesar el Iframe y modificar su html cuando se carga.
		this.processAndModifyIframe();

		// Seleccionar el estilo que corresponde al iframe segun el ancho del navegador.
		this.updateIframeWidth();
	}

	ngOnDestroy(): void {
	}

	processAndModifyIframe() {
		fromEvent($('#iframe'), 'load')
			.pipe(
				untilDestroyed(this),
				filter(() => this.url !== 'about:blank'),
				tap(() => {
					// console.log('SE LEYO EL IFRAME ', this.url);
					this.loaded();
				})
			).subscribe();
	}

	loaded() {
		this.loading = false;
		this._spinner.hide('iframe');
	}

	ngAfterViewInit() {

	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateIframeWidth();
	}

	updateIframeWidth() {
		const width = window.innerWidth;
		if (width <= 1200) {
			this.class = 'width-sm';
		} else if (width > 1024 && width <= 1366) {
			this.class = 'width-md';
		} else {
			this.class = 'width-lg';
		}
	}
}
