<mat-toolbar class="topbar topbar-white">
	<!-- Sidenav toggle button -->
	<button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()" matTooltip="Toggle Hide/Open">
		<mat-icon>menu</mat-icon>
	</button>
	<!-- Sidenav toggle collapse -->
	<button
		*ngIf="layoutConf.sidebarStyle !== 'closed'"
		mat-icon-button
		id="collapseToggle"
		fxHide.lt-md="true"
		(click)="toggleCollapse()"
		matTooltip="Toggle Collapse"
		class="toggle-collapsed"
	>
		<mat-icon>chevron_left</mat-icon>
	</button>
	<!-- Search form -->
	<div fxFlex fxHide.lt-sm="true" class="search-bar">
		<form class="top-search-form">
			<mat-icon role="img">search</mat-icon>
			<input autofocus="true" placeholder="Search" type="text" />
		</form>
	</div>
	<span fxFlex></span>
	<!-- Language Switcher -->
	<mat-select placeholder="" id="langToggle" [style.width]="'auto'" name="currentLang" [(ngModel)]="currentLang" (selectionChange)="setLang($event)">
		<mat-option *ngFor="let lang of availableLangs" [value]="lang.code" ngDefaultControl>{{ lang.name }}</mat-option>
	</mat-select>
	<!-- Theme Switcher -->
	<button
		mat-icon-button
		id="schemeToggle"
		[style.overflow]="'visible'"
		matTooltip="Color Schemes"
		[matMenuTriggerFor]="themeMenu"
		class="topbar-button-right"
	>
		<mat-icon>format_color_fill</mat-icon>
	</button>
	<mat-menu #themeMenu="matMenu">
		<mat-grid-list class="theme-list" cols="2" rowHeight="48px">
			<mat-grid-tile *ngFor="let theme of egretThemes" (click)="changeTheme(theme)">
				<div mat-menu-item [title]="theme.name">
					<div [style.background]="theme.baseColor" class="egret-swatch"></div>
					<mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>
				</div>
			</mat-grid-tile>
		</mat-grid-list>
	</mat-menu>
	<!-- Tarifarios toggle button -->
	<button mat-icon-button matTooltip="Tarifarios" (click)="toggleTarifario()" [style.overflow]="'visible'" class="topbar-button-right">
		<mat-icon>dashboard</mat-icon>
	</button>
	<!-- Notification toggle button -->
	<!-- <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'" class="topbar-button-right">
		<mat-icon>notifications</mat-icon>
		<span class="notification-number mat-bg-warn">3</span>
	</button> -->
	<!-- Top left user menu -->
	<button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
		<img src="assets/images/face-7.jpg" alt="" />
	</button>
	<mat-menu #accountMenu="matMenu">
		<button mat-menu-item [routerLink]="['/profile/overview']">
			<mat-icon>account_box</mat-icon>
			<span>Profile</span>
		</button>
		<button mat-menu-item [routerLink]="['/profile/settings']">
			<mat-icon>settings</mat-icon>
			<span>Account Settings</span>
		</button>
		<button mat-menu-item>
			<mat-icon>notifications_off</mat-icon>
			<span>Disable alerts</span>
		</button>
		<button mat-menu-item [routerLink]="['/sessions/signin']">
			<mat-icon>exit_to_app</mat-icon>
			<span>Sign out</span>
		</button>
	</mat-menu>
</mat-toolbar>
