import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';


function deleteDeprecatedCookies() {
	let client = environment.newClient;
	let domain = environment.newDomain;
	if (!client || !domain) {
		return;
	}
	let cookies = document.cookie.split(";");

	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i];
		let eqPos = cookie.indexOf("=");
		let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		if (name.includes(client)) {
			document.cookie = name + "=; path=/; domain="+domain+";expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	}
}

if (environment.production) {
	enableProdMode();
	if (window) {
		window.console.log = function () {
		};
	}
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
